/* eslint-disable react/jsx-no-target-blank */
import { graphql, Link, useStaticQuery, withPrefix } from "gatsby"
import React from "react"
import SEO from "../components/seo"
import useScript from "../hooks/use-script"
import Layout from "../components/layout"

const Features = (): JSX.Element => {
  const images = useStaticQuery(graphql`
    query {
      documentSigning: file(relativePath: { eq: "document-signing.svg" }) {
        publicURL
      }
      documentWorkflows: file(relativePath: { eq: "document-workflows.svg" }) {
        publicURL
      }
      reusableTemplates: file(relativePath: { eq: "reusable-template.svg" }) {
        publicURL
      }
      documentSharing: file(relativePath: { eq: "document-sharing.svg" }) {
        publicURL
      }
      apiIntegration: file(relativePath: { eq: "api-integration.svg" }) {
        publicURL
      }
      documentAuditing: file(relativePath: { eq: "document-auditing.svg" }) {
        publicURL
      }
      pdfConversion: file(relativePath: { eq: "pdf-conversion.svg" }) {
        publicURL
      }
    }
  `)

  useScript(withPrefix("js/show-on-scroll.js"))

  useScript(withPrefix("js/sticky-header.js"))

  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        url: "https://documentesign.com/features/",
        name:
          "eSignature Features and Solutions for all Businesses - Document eSign  ",
        isPartOf: {
          "@type": "WebSite",
          "@id": "https://documentesign.com/features/#website",
        },
        datePublished: "2021-02-16T07:56:57+00:00",
        dateModified: "2021-04-28T16:44:56+00:00",
        description:
          "Document eSign offers simple, secure and legal esignature solutions for your business with its rich set of features like document signing, sharing, tracking, templates, pdf conversions and more.",
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://documentesign.com/features/"],
          },
        ],
      },
    ],
  }

  return (
    <>
      <Layout>
        <SEO
          title="eSignature Features and Solutions for all Businesses - Document eSign"
          description="Document eSign offers simple, secure and legal esignature solutions for your business with its rich set of features like document signing, sharing, tracking, templates, pdf conversions and more."
          schemaMarkup={schema}
        />
        <div className="intro features-intro ani-in show-on-scroll pb-4 pb-lg-0">
          <div className="container">
            <div className="row">
              <div className="col-md-7 pt-lg-5 mt-lg-4 ani-top">
                <h1 className="pt-5">
                  Electronic signature <br />
                  that keeps your work moving
                </h1>
                <div className="h4 mt-4 pt-1 fw-regular">
                  Document eSign solutions make it easy to send, track and
                  collect eSignatures to modernize your business.
                </div>
                <a
                  href="https://app.documentesign.com/auth/signup"
                  target="_blank"
                  className="btn btn-dark mt-4 mb-2 px-md-5"
                >
                  Signup now &#8594;
                </a>
                <br />
                <small className="text-muted">
                  <strong>Free forever,</strong> No credit card required
                </small>
              </div>
            </div>
          </div>
        </div>

        <div className="container py-5 ">
          <div className="row py-5">
            <div className="col-sm-6 pr-lg-5 ani-in show-on-scroll">
              <img
                data-src={images.documentSigning.publicURL}
                className="img-fluid lazyload"
                alt="Document signing"
              />
            </div>
            <div className="col-sm-6 pt-5 ani-in show-on-scroll">
              <div className="h6 fw-semi-bold text-primary">
                DOCUMENT SIGNING
              </div>
              <h2>Close deals instantly</h2>
              <p className="mt-4">
                Document eSign offers legally binding e-signatures online right
                from small enterprises to big corporates. With around 40% faster
                document turnaround time it ensures sales acceleration, saves
                countless man-hours, closes the deal faster, without having to
                go through the excruciating process of printing, signing, and
                faxing. Document eSign ensures archived esign documents that are
                secured from any further changes in the document.
              </p>
              <Link to="/document-signing/" className="ani-link">
                Learn more
              </Link>
            </div>
          </div>

          <div className="row py-5">
            <div className="col-sm-6 order-sm-2 pl-lg-5 ani-in show-on-scroll">
              <img
                data-src={images.documentWorkflows.publicURL}
                className="img-fluid lazyload"
                alt="DOCUMENT WORKFLOWS"
              />
            </div>
            <div className="col-sm-6 pt-5 order-sm-1 ani-in show-on-scroll">
              <div className="h6 fw-semi-bold feature-02">
                DOCUMENT WORKFLOWS
              </div>
              <h2>Manage your documents</h2>
              <p className="mt-4">
                Document eSign lets you take control of your digital work. The
                default workflow gives you the leverage to decide if a document
                requires a simple signature or multi-form documents with
                multiple signatures, if people on board will sign their
                documents at once or rather have them sign online one-by-one in
                a specific order. For documents that need to be signed by
                multiple people, one by one in a specific order, you can
                determine who has to still complete the document.
              </p>
              <Link to="/document-workflows/" className="ani-link">
                Learn more
              </Link>
            </div>
          </div>

          <div className="row py-5">
            <div className="col-sm-6 pr-lg-5 ani-in show-on-scroll">
              <img
                data-src={images.reusableTemplates.publicURL}
                className="img-fluid lazyload"
                alt="REUSABLE TEMPLATES"
              />
            </div>
            <div className="col-sm-6 pt-5 ani-in show-on-scroll">
              <div className="h6 fw-semi-bold feature-03">
                REUSABLE TEMPLATES
              </div>
              <h2>Save time with reusable templates</h2>
              <p className="mt-4">
                Reuse and resend your saved template that can be used for any
                new sales document. Our predefined signatures and annotation
                fields help a great deal to instantly fill them out whenever
                needed. The broad range of automation tools and integrated
                connectors, easily create and configure templates that can be
                accessed online without having to delve deep into your emails or
                files. Our free Electronic Signature Service guides through the
                recipients with the process of completing the document to save
                time.
              </p>
              <Link to="/reusable-templates/" className="ani-link">
                Learn more
              </Link>
            </div>
          </div>

          <div className="row py-5">
            <div className="col-sm-6 order-sm-2 pl-lg-5 ani-in show-on-scroll">
              <img
                data-src={images.documentSharing.publicURL}
                className="img-fluid lazyload"
                alt="DOCUMENT SHARING AND TRACKING"
              />
            </div>
            <div className="col-sm-6 pt-5 order-sm-1 ani-in show-on-scroll">
              <div className="h6 fw-semi-bold feature-04">
                DOCUMENT SHARING AND TRACKING
              </div>
              <h2>Flexible sharing and tracking</h2>
              <p className="mt-4">
                Document eSign determines any document completion through the
                business process. The automated documents and presentations (
                both read-only and fillable) can be easily shared and tracked,
                the status of which will be notified on the dashboard once
                anything you share gets viewed.
              </p>
              <Link to="/document-sharing-tracking/" className="ani-link">
                Learn more
              </Link>
            </div>
          </div>

          <div className="row py-5">
            <div className="col-sm-6 pr-lg-5 ani-in show-on-scroll">
              <img
                data-src={images.apiIntegration.publicURL}
                className="img-fluid lazyload"
                alt="API INTEGRATION"
              />
            </div>
            <div className="col-sm-6 pt-5 ani-in show-on-scroll">
              <div className="h6 fw-semi-bold feature-05">API INTEGRATION</div>
              <h2>Powerful API library</h2>
              <p className="mt-4">
                Our Embed e-signing document capabilities enable easy
                integration of electronic signatures to your mobile & web
                applications, websites and core systems. Document eSign’s API
                library, enables you to build customized solutions for your
                online electronic signature requirements. Our fully featured
                REST API helps automate all the functionalities of your existing
                technology infrastructure.
              </p>
              <Link to="/api-integration/" className="ani-link">
                Learn more
              </Link>
            </div>
          </div>

          <div className="row py-5">
            <div className="col-sm-6 order-sm-2 pl-lg-5 ani-in show-on-scroll">
              <img
                data-src={images.documentAuditing.publicURL}
                className="img-fluid lazyload"
                alt="DOCUMENT AUDITING"
              />
            </div>
            <div className="col-sm-6 pt-5 order-sm-1 ani-in show-on-scroll">
              <div className="h6 fw-semi-bold feature-06">
                DOCUMENT AUDITING
              </div>
              <h2>Comprehensive audit trail</h2>
              <p className="mt-4">
                Document eSign helps you sign, track and maintain the audit
                trail of the significant activities related to an esign document
                to avoid any potential mishaps. Audit reports are pushed for
                every signed legal document adhering to the international
                e-signature law requirements. The audit trail will contain the
                names, email, IP addresses, and time stamps and store the
                detailed audit trail of any activities in your document.
              </p>
              <Link to="/document-auditing/" className="ani-link">
                Learn more
              </Link>
            </div>
          </div>

          <div className="row py-5">
            <div className="col-sm-6 pr-lg-5 ani-in show-on-scroll">
              <img
                data-src={images.pdfConversion.publicURL}
                className="img-fluid lazyload"
                alt="ESIGN PDF CONVERSION"
              />
            </div>
            <div className="col-sm-6 pt-5 ani-in show-on-scroll">
              <div className="h6 fw-semi-bold feature-07">
                ESIGN PDF CONVERSION
              </div>
              <h2>Convert into fillable PDF</h2>
              <p className="mt-4">
                Use Document eSign free electronic signature services to convert
                your word documents into pdf in minutes without the need to
                print, scan, or fax. Download and share your file and increase
                your productivity to make transactions faster and easier.
                Retrieve your signed documents saved in the cloud for easy
                access.
              </p>
              <Link to="/pdf-conversion/" className="ani-link">
                Learn more
              </Link>
            </div>
          </div>
          <div className="row py-5">
            <div className="col-sm-6 pr-lg-5 order-sm-1 ani-in show-on-scroll">
              <img
                data-src="/images/dropbox-gdrive.png"
                className="img-fluid lazyload"
                alt="ESIGN PDF CONVERSION"
              />
            </div>
            <div className="col-sm-6 pt-5 ani-in show-on-scroll">
              <div className="h6 fw-semi-bold feature-02  ">
                CLOUD STORAGE INTEGRATION
              </div>
              <h2>Add multiple documents from your drive or cloud storing </h2>
              <p className="mt-4">
                Document eSign integration with Google Drive and dropbox enables
                users to upload them directly. Open any DOC, DOCX, PDF, PNG or
                JPG in SignNow from within Google Drive or Dropbox to then send
                out for signing.
              </p>
              <Link to="/storage-integration/" className="ani-link">
                Learn more
              </Link>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Features
